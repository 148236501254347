/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(1, 39, 84, 0.1);
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

* {
  box-sizing: border-box;
}

body {
  overflow: auto; /* firefox fallback */
  overflow: overlay;
}

body > iframe[style*='2147483647'] {
  display: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  background: white;
  width: 100%;
  height: 100%;
  position: relative;
}

#root {
  font-family: avenir next, avenir, helvetica neue, helvetica, roboto, sans-serif;
}

.background-details-circle-border {
  height: 60px;
  width: 60px;
  border: 7px solid #ffc29a;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.background-details-circle-border-sm {
  height: 30px;
  width: 30px;
  border: 4px solid #ffe4ab;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}

.background-details-circle-full {
  height: 38px;
  width: 38px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  background: #ffc29a;
}

.background-details-triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #ffe4ab;
}

.background-details-triangle-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 30px solid #ffe4ab;
}

.triangle {
  position: relative;
  width: 0;
  border-bottom: solid 50px #ffe4ab;
  border-right: solid 30px transparent;
  border-left: solid 30px transparent;
}

.triangle::after {
  content: '';
  position: absolute;
  top: 9px;
  left: -21px;
  width: 0;
  border-bottom: solid 36px #f6faff;
  border-right: solid 21px transparent;
  border-left: solid 21px transparent;
}

.background-details-cross {
  background: #ffc29a;
  height: 25px;
  position: relative;
  width: 5px;
}

.background-details-cross::after {
  background: #ffc29a;
  content: '';
  height: 5px;
  left: -10px;
  position: absolute;
  top: 10px;
  width: 25px;
}

.transform-cross {
  transform: rotateZ(45deg);
}

.pos-1 {
  position: absolute;
  top: 15%;
  left: 1%;
}

.pos-2 {
  position: absolute;
  top: 14%;
  left: 90%;
}

.pos-3 {
  position: absolute;
  top: 83%;
  left: 10%;
}

.pos-4 {
  position: absolute;
  top: 85%;
  right: 40%;
}

.pos-5 {
  position: absolute;
  top: 85%;
  left: 5%;
}

.pos-6 {
  position: absolute;
  top: 3%;
  left: 88%;
}

.PhoneInputCountryIcon {
  border: none !important;
  height: 1.1em !important;
  font-size: 18px;
  margin-left: 10px;
}

.PhoneInputInput {
  border: none !important;
  background: none !important;
}

.PhoneInputCountrySelectArrow {
  width: 0 !important;
  height: 0 !important;
  margin-bottom: 0.1em !important;
  margin-top: 0.3em !important;
  margin-left: 0.3em !important;
  border-bottom-width: 0 !important;
  border-top-width: 0.35em !important;
  border-bottom-style: solid !important;
  border-top-style: solid !important;
  border-left: 0.2em solid transparent !important;
  border-right: 0.2em solid transparent !important;
  color: #b8bdc4 !important;
  opacity: .7 !important;
  transition: color .1s !important;
  transform: none !important;
}

.ant-form-item-has-error .PhoneInput {
  border: 1px solid #ff0000;
}

.react-phone-number-input__row {
  height: 40px;
}

.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  content: none;
}

.yes-button-styled {
   /* SNP Primary/Indigo-3077C9 */
  background: #3077C9 !important;
  color: #fff;
}

.yes-button-styled > span {
  color: #fff !important;
}

.yes-button-styled:hover {
  background: #2864A8 !important;
  color: #fff;
  border-color: #40a9ff !important;
}

#launcher-icon-container.hidden {
  display: none;
}

/* remove cookie consent floating button */
#ot-sdk-btn-floating {
  display: none;
}